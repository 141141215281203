<template>
  <div></div>
</template>

<script>
import LocalStorageService from "@/plugins/LocalStorageService";
const localStorageService = LocalStorageService.getService();
export default {
  name: "LogoutSuccess",
  created() {
    //this.$router.query
    const { redirect } = this.$route.query;
    localStorageService.clearToken();
    const apiUrl = process.env.VUE_APP_API_URL;

    console.log("==========");
    if (redirect) {
      location.href = redirect;
    } else {
      location.href = `${apiUrl}/api/logout?vueUrl=${encodeURIComponent(window.location.origin)}`;
      //this.$router.push({ name: "login" });
    }
  },
};
</script>
